/* Have to set height explicity on ui-view
to prevent collapsing during animation*/
[ui-view] {
  -webkit-transition: all 600ms ease-out;
  -moz-transition: all 600ms ease-out;
  -o-transition: all 600ms ease-out;
  transition: all 600ms ease-out;

  &.ng-leave {
    opacity: 0;
  }

  &.ng-enter {
    opacity: 0;
  }

  &.ng-enter-active {
    opacity: 1;
  }

  &.ng-leave-active {
    opacity: 0;
  }

  &.off {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 320px;
    opacity: 1;
    &.ng-enter {
      transform: translate3d(320px, 0, 0);
      opacity: 1;
    }

    &.ng-enter-active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    &.ng-leave {
      transform: translate3d(320px, 0, 0);
      opacity: 1;
    }

    &.ng-leave-active {
      transform: translate3d(320px, 0, 0);
      opacity: 1;
    }
  }
}
