//NEW TV4 FONT--------------------

@font-face {
  font-family: 'quattro_news';
  src: url('../fonts/QuattroNews-Bold.woff2') format('woff2'), url('../fonts/QuattroNews-Bold.woff') format('woff'),
    url('../fonts/quattronews-bold.ttf') format('truetype'),
    url('../fonts/quattronews-bold.svg#quattro_newsbold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'quattro_news';
  src: url('../fonts/QuattroNews-Italic.woff2') format('woff2'), url('../fonts/QuattroNews-Italic.woff') format('woff'),
    url('../fonts/quattronews-italic.ttf') format('truetype'),
    url('../fonts/quattronews-italic.svg#quattro_newsitalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'quattro_news';
  src: url('../fonts/QuattroNews-Light.woff2') format('woff2'), url('../fonts/QuattroNews-Light.woff') format('woff'),
    url('../fonts/quattronews-light.ttf') format('truetype'),
    url('../fonts/quattronews-light.svg#quattro_newslight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'quattro_news';
  src: url('../fonts/QuattroNews-Medium.woff2') format('woff2'), url('../fonts/QuattroNews-Medium.woff') format('woff'),
    url('../fonts/quattronews-medium.ttf') format('truetype'),
    url('../fonts/quattronews-medium.svg#quattro_newsmedium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'quattro_news';
  src: url('../fonts/QuattroNews-MediumItalic.woff2') format('woff2'),
    url('../fonts/QuattroNews-MediumItalic.woff') format('woff'),
    url('../fonts/quattronews-mediumitalic.ttf') format('truetype'),
    url('../fonts/quattronews-mediumitalic.svg#quattro_newsmedium_italic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'quattro_news';
  src: url('../fonts/QuattroNews-Regular.woff2') format('woff2'),
    url('../fonts/QuattroNews-Regular.woff') format('woff'), url('../fonts/quattronews-regular.ttf') format('truetype'),
    url('../fonts/quattronews-regular.svg#quattro_newsregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'quattro_news';
  src: url('../fonts/QuattroNews-UltraBold.woff2') format('woff2'),
    url('../fonts/QuattroNews-UltraBold.woff') format('woff'),
    url('../fonts/quattronews-ultrabold.ttf') format('truetype'),
    url('../fonts/quattronews-ultrabold.svg#quattro_newsultra_bold') format('svg');
  font-weight: 700;
  font-style: normal;
}
