spinner.align-middle {
  position: absolute;
  left: 50%;
  top: 55%;
}

.loading {
  padding-left: 60px;
  position: relative;
  &::before,
  &::after {
    content: ' ';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $tv4-red;
    opacity: 0.6;
    position: absolute;
    top: 0px;
    left: 0px;

    -webkit-animation: bounce 2s infinite ease-in-out;
    animation: bounce 2s infinite ease-in-out;
  }

  &::after {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  &.huge {
    padding-left: 0;
    &::before,
    &::after {
      width: 120px;
      height: 120px;
    }
  }

  &.center {
    left: 50%;
    transform: translateX(-20%);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.loader {
  padding: 8px 0px;
  .dot-loader {
    height: 32px;
    width: 32px;
    background: $tv4-red;
    border-radius: 50%;
    animation: pulse 2s ease-in-out infinite;

    &.huge {
      height: 72px;
      width: 72px;
    }

    &.fast {
      animation-duration: 1.5s;
    }

    &.fit-inside-button {
      display: inline-block;
      margin-left: 8px;
      height: 16px;
      width: 16px;
      position: relative;
      top: 2px;
    }

    &.white {
      background: $full-white;
    }
  }
  &.centered {
    @include flexbox();
    justify-content: center;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.25);
  }
  100% {
    transform: scale(1);
  }
}

.feather {
  position: relative;
  /*height: 40px;
    width: 40px;
    float: left;*/
  .feather--base {
    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 1;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation: expand-left-base 2.75s infinite;
      -webkit-animation: expand-left-base 2.75s infinite;
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    position: absolute;
    width: 28px;
    height: 20px;

    &:after {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 1;
      animation: expand-right-base 2.75s infinite;
      -webkit-animation: expand-right-base 2.75s infinite;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }

  .feather--layer-1 {
    &:before {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.8;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation: expand-left-1 2.75s infinite;
      -webkit-animation: expand-left-1 2.75s infinite;
      animation-delay: 0.4s;
      -webkit-animation-delay: 0.4s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    position: absolute;
    width: 28px;
    height: 20px;

    &:after {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.8;
      animation: expand-right-1 2.75s infinite;
      -webkit-animation: expand-right-1 2.75s infinite;
      animation-delay: 0.4s;
      -webkit-animation-delay: 0.4s;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }
  .feather--layer-2 {
    &:before {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.6;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation: expand-left-2 2.75s infinite;
      -webkit-animation: expand-left-2 2.75s infinite;
      animation-delay: 0.8s;
      -webkit-animation-delay: 0.8s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    position: absolute;
    width: 28px;
    height: 20px;

    &:after {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.6;
      animation: expand-right-2 2.75s infinite;
      -webkit-animation: expand-right-2 2.75s infinite;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation-delay: 0.8s;
      -webkit-animation-delay: 0.8s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }

  .feather--layer-3 {
    &:before {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.4;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation: expand-left-3 2.75s infinite;
      -webkit-animation: expand-left-3 2.75s infinite;
      animation-delay: 1.2s;
      -webkit-animation-delay: 1.2s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    position: absolute;
    width: 28px;
    height: 20px;

    &:after {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.4;
      animation: expand-right-3 2.75s infinite;
      -webkit-animation: expand-right-3 2.75s infinite;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation-delay: 1.2s;
      -webkit-animation-delay: 1.2s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }

  .feather--layer-4 {
    &:before {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.2;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation: expand-left-4 2.75s infinite;
      -webkit-animation: expand-left-4 2.75s infinite;
      animation-delay: 1.5s;
      -webkit-animation-delay: 1.5s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    position: absolute;
    width: 28px;
    height: 20px;

    &:after {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.2;
      animation: expand-right-4 2.75s infinite;
      transform-origin: center bottom;
      animation-delay: 1.5s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }

  .feather--layer-5 {
    &:before {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.2;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation: expand-left-5 2.75s infinite;
      -webkit-animation: expand-left-5 2.75s infinite;
      animation-delay: 1.5s;
      -webkit-animation-delay: 1.5s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    position: absolute;
    width: 28px;
    height: 20px;

    &:after {
      position: absolute;
      top: 0;
      left: 50%;

      content: ' ';

      width: 14px;
      height: 20px;
      background-color: $tv4-red;
      border-radius: 50% / 40% 40% 60% 60%;
      opacity: 0.2;
      animation: expand-right-5 2.75s infinite;
      -webkit-animation: expand-right-5 2.75s infinite;
      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
      animation-delay: 1.5s;
      -webkit-animation-delay: 1.5s;
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  }

  &.huge {
    width: 180px;
    height: 180px;

    .feather--base,
    .feather--layer-1,
    .feather--layer-2,
    .feather--layer-3,
    .feather--layer-4,
    .feather--layer-5 {
      &:before,
      &:after {
        height: 80px;
        width: 56px;
      }
    }
  }

  &.medium {
    width: 180px;
    height: 180px;

    .feather--base,
    .feather--layer-1,
    .feather--layer-2,
    .feather--layer-3,
    .feather--layer-4,
    .feather--layer-5 {
      &:before,
      &:after {
        height: 36px;
        width: 28px;
      }
    }
  }

  &.large {
    min-width: 30px;
    min-height: 30px;

    .feather--base,
    .feather--layer-1,
    .feather--layer-2,
    .feather--layer-3,
    .feather--layer-4,
    .feather--layer-5 {
      &:before,
      &:after {
        height: 56px;
        width: 42px;
      }
    }
  }

  &.center {
    left: 50%;
    transform: translateX(-20%);
  }
  &.centered {
    @include flexbox();
    justify-content: center;
  }

  &.pride {
    .feather--base {
      &:before,
      &:after {
        background-color: #a000c0 !important;
        border: 0 !important;
      }
    }
    .feather--layer-1 {
      &:before,
      &:after {
        background-color: #0000ff !important;
        border: 0 !important;
      }
    }
    .feather--layer-2 {
      &:before,
      &:after {
        background-color: #008000 !important;
        border: 0 !important;
      }
    }
    .feather--layer-3 {
      &:before,
      &:after {
        background-color: #ffff00 !important;
        border: 0 !important;
      }
    }
    .feather--layer-4 {
      &:before,
      &:after {
        background-color: #ff8000 !important;
        border: 0 !important;
      }
    }
    .feather--layer-5 {
      &:before,
      &:after {
        background-color: #ff0000 !important;
        border: 0 !important;
      }
    }
  }
}

@include keyframes(expand-left-base) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(-180deg));
  }
  98% {
    @include transform(scale(1) rotate(-180deg));
  }
  100% {
    @include transform(scale(0) rotate(-180deg));
    transform: scale(0) rotate(-180deg);
  }
}

@include keyframes(expand-right-base) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(180deg));
  }
  98% {
    @include transform(scale(1) rotate(180deg));
  }
  100% {
    @include transform(scale(0) rotate(180deg));
  }
}

@include keyframes(expand-left-1) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(-150deg));
  }
  96% {
    @include transform(scale(1) rotate(-150deg));
  }
  100% {
    @include transform(scale(0) rotate(-150deg));
  }
}

@include keyframes(expand-right-1) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(150deg));
  }
  96% {
    @include transform(scale(1) rotate(150deg));
  }
  100% {
    @include transform(scale(0) rotate(150deg));
  }
}

@include keyframes(expand-left-2) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(-120deg));
  }
  94% {
    @include transform(scale(1) rotate(-120deg));
  }
  100% {
    @include transform(scale(0) rotate(-120deg));
  }
}

@include keyframes(expand-right-2) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(120deg));
  }
  94% {
    @include transform(scale(1) rotate(120deg));
  }
  100% {
    @include transform(scale(0) rotate(120deg));
  }
}

@include keyframes(expand-left-3) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(-90deg));
  }
  92% {
    @include transform(scale(1) rotate(-90deg));
  }
  100% {
    @include transform(scale(0) rotate(-90deg));
  }
}

@include keyframes(expand-right-3) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(90deg));
  }
  92% {
    @include transform(scale(1) rotate(90deg));
  }
  100% {
    @include transform(scale(0) rotate(90deg));
  }
}

@include keyframes(expand-left-4) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(-60deg));
  }
  90% {
    @include transform(scale(1) rotate(-60deg));
  }
  100% {
    @include transform(scale(0) rotate(-60deg));
  }
}

@include keyframes(expand-right-4) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(60deg));
  }
  90% {
    @include transform(scale(1) rotate(60deg));
  }
  100% {
    @include transform(scale(0) rotate(60deg));
  }
}

@include keyframes(expand-left-5) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(-30deg));
  }
  90% {
    @include transform(scale(1) rotate(-30deg));
  }
  100% {
    @include transform(scale(0) rotate(-30deg));
  }
}

@include keyframes(expand-right-5) {
  0% {
    @include transform(scale(0));
  }
  10% {
    @include transform(scale(1));
  }
  80% {
    @include transform(scale(1) rotate(30deg));
  }
  90% {
    @include transform(scale(1) rotate(30deg));
  }
  100% {
    @include transform(scale(0) rotate(30deg));
  }
}
