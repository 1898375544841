.content,
.content__container {
  width: $content-width--default;
  padding-left: $content-padding--default;
  padding-right: $content-padding--default;
  margin: 0 auto;
}

/* 1199 - 1024 */
@include desktop {
  html,
  body {
    width: 100%;
  }

  .content,
  .content__container {
    width: $content-width--desktop !important;
    margin: 0 auto !important;
    padding-left: $content-padding--desktop;
    padding-right: $content-padding--desktop;
  }
}

/* 1024-768 */
@include tablet {
  html,
  body {
    width: 100%;
  }

  .content,
  .content__container {
    width: $content-width--tablet;
    margin: 0;
    padding-left: $content-padding--tablet;
    padding-right: $content-padding--tablet;
  }
}

/* 758 - 1 */
@include phone {
  html,
  body {
    width: 100%;
  }

  .content,
  .content__container {
    width: $content-width--phone;
    margin: 0;
    padding-left: $content-padding--phone;
    padding-right: $content-padding--phone;
  }
}
