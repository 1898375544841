.ngx-slider {
  &[disabled] .ngx-slider-pointer {
    background-color: $mid-gray--darkest !important;
  }
  &[disabled] .ngx-slider-pointer:after {
    background-color: $full-white !important;
  }
  &[disabled] .ngx-slider-selection,
  .ngx-slider-bar {
    background-color: $mid-gray--darkest !important;
  }

  .ngx-slider-selection {
    background-color: $tv4-red !important;
  }

  .ngx-slider-pointer {
    background-color: $full-white !important;
  }
  .ngx-slider-pointer:after {
    background-color: $tv4-red !important;
  }
}
