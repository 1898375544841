.campaign {
}

.booking__container {
  display: block;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
  position: relative;

  .help__text {
    @extend .animate;
    display: block;
    position: fixed;
    right: calc(25% + 70px);
    max-width: 1000px;
    bottom: 0;
    font-size: 12px;
    bottom: 0px;
    text-align: right;
    width: 100%;
    color: $brown;
    opacity: 1;
    padding-right: $spacing-4x;

    .key__button {
      display: inline-block;
      color: #aaa;
      font: bold 9pt arial;
      text-decoration: none;
      text-align: center;
      width: 22px;
      height: 20px;
      margin: 5px;
      margin-top: -5px;
      background: #222;
      -moz-border-radius: 4px;
      border-radius: 4px;
      border-top: 1px solid #222;
      -webkit-box-shadow: inset 0 0 25px #333, 0 1px 0 #000, 0 2px 0 #222, 0 2px 3px #333;
      -moz-box-shadow: inset 0 0 25px #333, 0 1px 0 #000, 0 2px 0 #222, 0 2px 3px #333;
      box-shadow: inset 0 0 25px #333, 0 1px 0 #000, 0 2px 0 #222, 0 2px 3px #333;
      text-shadow: 0px -1px 0px #000;
      font-size: 12px;
      line-height: 20px;
      font-weight: $light;

      &.key--press {
        color: #fff;
        background: #222;
        margin: 7px 5px 3px;
        -webkit-box-shadow: inset 0 0 25px #333, 0 0 3px #333;
        -moz-box-shadow: inset 0 0 25px #333, 0 0 3px #333;
        box-shadow: inset 0 0 25px #333, 0 0 3px #333;
        border-top: 1px solid #000;
      }
    }

    &.hidden {
      opacity: 0;
    }
  }

  .button__group {
    display: block;
    position: fixed;
    bottom: 0;
    right: calc(25% + 70px);
    max-height: 150px;
    max-width: 1000px;
    padding-bottom: 24px;
    z-index: 20;
    margin-right: 32px;

    .button {
      margin: 4px;
    }

    .round {
      padding: $spacing-2x;
    }

    .icon-arrow {
      &:before {
        display: inline-block;
      }
    }
  }

  .slider {
    @extend .animate;

    .slide {
      @extend .clearfix;
      overflow-y: auto;
      height: calc(100vh - 100px);
      margin-right: $spacing-4x;
      overflow-x: hidden;
      // Instead of padding-bottom, to bypass different behaviors in chrome and firefox
      &:after {
        content: '';
        height: 100px;
        display: block;
      }

      .shrink--panel {
        font-size: 14px;

        h1 {
          font-size: 28px;
          margin: $spacing-1x 0;
        }

        h4,
        h5 {
          font-size: 16px;
          margin: 8px 0 2px 0;
        }

        .account__card {
          padding: $spacing-1x $spacing-3x;
        }

        .tabbable__body {
          padding: $spacing-1x $spacing-2x;
        }

        input[type='radio'] {
          &.radio {
            & ~ label.radio {
              font-size: 14px;
              line-height: 20px;
              margin-left: 30px;
              margin-right: 10px;
              margin-top: 4px;
              margin-bottom: 4px;
              padding-left: 0px;

              &:before {
                left: -30px;
                width: 20px;
                height: 20px;
              }
              &:after {
                top: 3px;
                left: -27px;
                width: 14px;
                height: 14px;
              }
            }

            &:checked {
              & ~ label.radio {
                &:before {
                  left: -30px;
                  width: 20px;
                  height: 20px;
                }

                &:after {
                  font-size: 14px;
                  border: 2px solid $mid-gray--darkest;
                }
              }
            }
          }

          &:disabled,
          &.disabled {
            &.radio {
              & ~ label.radio {
                &:after {
                  line-height: 1;
                  font-size: 16px;
                }
                &.publisher {
                  &:after {
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }

        input[type='checkbox'] {
          &.checkbox {
            & ~ label.checkbox {
              font-size: 14px;
              line-height: 20px;
              margin-left: 30px;
              margin-right: 10px;
              margin-top: 4px;
              margin-bottom: 4px;
              padding-left: 0px;

              &:before {
                left: -30px;
                width: 20px;
                height: 20px;
              }
              &:after {
                line-height: 20px;
                font-size: 12px;
                top: 0;
                left: -30px;
                width: 20px;
                height: 20px;
              }
            }

            &:checked {
              & ~ label.checkbox {
                &:before {
                  left: -30px;
                  width: 20px;
                  height: 20px;
                }

                &:after {
                  font-size: 12px;
                }
              }
            }

            &:disabled {
              .publisher {
                opacity: 0.3;
              }
            }
          }
        }
      }
    }
  }
}

.campaign-sidebar {
  position: fixed;
  width: 25%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 40;
  background-color: $full-white;
  padding-left: $spacing-2x;
  padding-top: 116px;
  padding-right: $spacing-2x;
  overflow: auto;
}

.campaign-display {
  position: fixed;
  right: -75%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: $full-white;
  transition: all 1000ms ease-in-out;
  padding-top: 100px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  h4 {
    margin: 0;
  }

  h4 + h1 {
    margin-top: 0;
  }

  .command-panel {
    background-color: $full-white;
    width: 70px;
    height: 100%;
    display: flex;
    position: relative;
    float: left;
    box-shadow: -1px 0 9px 1px lightgrey;
    &:hover {
      .circle {
        @extend .shadow-z-6;
      }
    }
  }
  .campaign-details {
    width: calc(25% - 70px);
    display: flex;
    float: left;
    height: 100%;
    background-color: $light-gray;
    padding: 0 $spacing-1x;
    overflow: auto;
  }
  .campaign-info {
    $horizontal-padding: $spacing-4x;
    width: 75%;
    display: flex;
    float: left;
    background-color: $light-gray--primary;
    height: 100%;
    overflow: auto;
    padding: 0 $horizontal-padding;
    position: relative;
    .serie {
      max-height: 0px;
      overflow: hidden;
      transition: all 500ms ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      &.open {
        max-height: 1000px;
      }
    }
    .followup {
      $test: 1280px;
      max-width: calc(
        100% - ((100vw - (#{$content-width--default} + (#{$content-padding--default} * 2))) / 2) -
          (#{$horizontal-padding} * 2)
      );
    }
  }

  .info-block {
    width: 100%;
    display: block;
    padding: 0 $spacing-1x;
    transition: all 600ms ease-in-out;
  }

  .chart-block {
    width: 100%;
    padding: 0 $spacing-1x;
    max-height: 1000px;
    transition: all 400ms linear;
    margin: 16px 0;

    video {
      height: 155px;
    }

    &.close {
      max-height: 0px;
      opacity: 0;
    }
    .chart-container {
      canvas {
        height: 200px !important;
      }
    }
  }
  canvas {
    transition: all 600ms ease-in-out;
    width: 100%;
  }

  video {
    width: 100%;
    max-width: 1024px;
  }

  &.take-over {
    -webkit-transform: translate3d(-75%, 0, 0);
    transform: translate3d(-75%, 0, 0);
  }
}

.panels {
  &.compact {
    display: block;
    margin-bottom: $spacing-4x;
    .slide {
      height: inherit;
      transition: all 600ms ease-in-out;
      .next,
      .previous {
        display: none;
      }
    }
  }

  .progress {
    position: fixed;
    top: 0;
    right: 25%;
    width: 70px;
    background-color: $mid-gray--darker;
    height: 100%;
    overflow: visible;
    z-index: 100;

    .bar--hide {
      position: absolute;
      width: 5px;
      height: 400px;
      z-index: 50;
      background-color: $mid-gray--darker;
    }
  }
}

.feedback {
  position: fixed;
  top: 100px;
  right: calc(25% + 78px);
  z-index: 100;
  width: 300px;
}

.required {
  position: absolute;
  right: 8px;
  bottom: 0;
  margin: 0;
  font-size: 16px;
  color: $error-red;
  line-height: 60px;

  &.top {
    bottom: auto;
    top: 0;
  }

  &.medium {
    line-height: 45px;
  }
  &.extra-right-margin {
    right: 16px;
  }
}

.required-text {
  font-size: 14px;
}

.creative__tab {
  position: relative;
  z-index: 2;
}

.creative-selection {
  padding: $spacing-1x 0;
}

.status__badge {
  &.ag {
    display: inline-block;
    text-align: center;
    top: -10px;
    width: 14px;
    height: 14px;
    font-size: 10px;
    padding: 0;
    line-height: 15px;
  }
}

.radio {
  &.ag {
    font-size: 15px !important;
    padding-left: 4px !important;
    margin: 4px 0 !important;
    margin-left: 30px !important;
  }
}

.creative--type {
  display: inline-block;
  margin-right: 8px;
}

/* 1199 - 1024 */
@include desktop {
  .campaign-display {
    .campaign-details {
      width: calc(35% - 70px);
      padding: 0;
    }

    .campaign-info {
      width: 65%;
    }
  }
}

/* 1024-768 */
//@media only screen and (max-width : 1024px) and (min-width : 768px)  {
@include tablet {
  .booking__container .slider .slide {
    margin-right: 4px;
  }

  .campaign-display {
    right: -85%;
    padding-top: 50px;
    transition: all 600ms ease-in-out;

    .campaign-details {
      width: calc(35% - 70px);
      padding: 0;
    }

    .campaign-info {
      width: 65%;
    }

    &.take-over {
      -webkit-transform: translate3d(-85%, 0, 0);
      transform: translate3d(-85%, 0, 0);
    }
  }

  .panels {
    padding: $spacing-3x $spacing-4x;
    display: block;
    .progress {
      top: 50px;
      height: calc(100% - 50px);
      right: 30%;
      width: 38px;
    }
  }

  .campaign-sidebar {
    padding: $spacing-1x;
    padding-top: 58px;
    width: 30%;
  }

  #creatives {
    .section {
      padding: $spacing-1x 0;
    }
    input,
    select {
      margin: $spacing-1x;
    }
    input[type='url'] {
      margin-top: 20px;
    }
  }
}

/* 758 - 1 */
@include phone {
  .campaign {
    width: 100%;
  }
  .campaign-display {
    right: -100%;
    padding-top: 50px;
    transition: all 600ms ease-in-out;
    overflow: scroll;

    .command-panel {
      width: 20px;
      button {
        transition: all 1000ms ease-in-out;
        height: 20px;
        padding: 0;
        .icon-chevron_left {
          font-size: 20px;
          display: block;
          width: 100%;
        }
      }
    }
    .campaign-details {
      width: calc(100% - 20px);
      padding: 0;
    }

    .campaign-info {
      border-left: 20px solid $mid-gray--darker;
      width: 100%;
      height: initial;
      padding: 0 $spacing-1x;
    }

    &.take-over {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .campaign-sidebar {
    padding: $spacing-1x;
    padding-top: 58px;
    width: 100%;
    right: -100%;
  }

  #creatives {
    .section {
      padding: $spacing-1x 0;
    }
    input,
    select {
      margin: 4px 0;
    }
  }
}

.impression__loader {
  @extend .animate--fast;

  position: absolute;
  top: -2px;
  right: 32px;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.requesting__impressions {
  -webkit-animation: blink 2s infinite linear;
  animation: blink 2s infinite linear;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.reset--input {
  background: transparent;
  border: 0;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 0;
  font-size: 18px;
  overflow: visible;
  width: 33%;
  line-height: 1;
  -web-kit-appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.regional--span {
  font-size: 18px;
}

.reset--label {
  width: 100%;
  display: block;
}

.creative__box {
  width: 20px;
  height: 20px;
  border: 1px solid $mid-gray--darkest;
  border-radius: 3px;
  float: left;
  margin-right: 8px;
}

.disabled__cover {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba($highlighted-gray, 0.75);
  z-index: 1000;
  font-size: 50px;
  padding-top: 55%;
  text-align: center;
  color: $full-white;
}

.selection__button {
  width: 30%;
  display: flex;
  float: left;
  border: 1px solid $mid-gray--darkest;
  background-color: $full-white;
  margin: $spacing-1x;
  padding: $spacing-2x;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
}

.force--scroll {
  overflow: scroll !important;
}

.publisher__container {
  margin-bottom: $spacing-1x;
  img {
    width: 200px;
  }

  .fixed-height {
    height: 110px;
  }

  .checkbox {
    line-height: 120px !important;
    top: 50% !important;
    transform: scale(1) translateY(-50%);
  }

  .radio {
    &:checked {
      & ~ label.radio {
        &:after {
          top: 50% !important;
          transform: scale(1) translateY(-50%);
        }
      }
    }
  }

  label {
    &.radio {
      &:before {
        top: 50% !important;
        transform: scale(1) translateY(-50%);
      }
    }
    &.disabled {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
}

.toggle__container {
  display: inline-block;
  margin: 0 $spacing-2x;
}

.margin--top {
  margin-top: 21px;
}

.scrollable__list {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $highlighted-gray;
}

.scrollable__list--fixed {
  height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $highlighted-gray;
}

.checkbox:disabled {
  & ~ label.checkbox {
    .tease__content {
      opacity: 0.4;
    }
  }
}

.selection__grid {
  .row {
    @include flexbox();
    @include flex-flow(row wrap);

    .column--padding {
      @include flexbox();
      @include flex(1);
      padding-right: 2px;
      padding-left: 8px;
      margin-bottom: $spacing-1x;
    }
  }
  .label--high {
    height: 100%;
  }
  .special--margins {
    margin-left: 45px;
    input {
      margin-top: 0px;
      height: 45px;
    }
  }
}

.selection__card {
  @extend .shadow-z-1;
  @extend .animate;
  border-bottom: 3px solid $mid-gray--darker;
  background-color: $full-white;
  padding: $spacing-1x $spacing-3x;
  height: 100%;
  margin-right: $spacing-1x;
  display: block;
  @include flexbox();
  @include flex(1);
  height: inherit;

  .icon-sveden {
    font-size: 30px;
  }
}

.image__dropbox {
  border: 4px dashed $dark-gray;
  text-align: center;
  padding: $spacing-2x;
  border-radius: 8px;
  min-height: 285px;
  &:hover {
    background-color: $highlighted-gray;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background: $highlighted-gray;
  }
  &.file-hover {
    border-style: solid;
  }
}

.creative__thumbnail {
  width: 100px;
}

.summary__slide {
  a {
    &:hover {
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
  h5 {
    margin-top: 16px;
    margin-bottom: 4px;
  }
}

.slider__container {
  margin-left: 55px;
  margin-top: 8px;
}

.target-group__container {
  margin: 2px 0;
}

.hover--gray:hover,
.hover--gray .checkbox.ng-empty.ng-dirty ~ label {
  img {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}
