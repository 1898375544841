.ngdialog {
  a {
    color: #000;
  }
  &.ngdialog-theme-default .ngdialog-content {
    width: 66%;
    max-width: 700px;
    border-radius: 34px;
    padding: $spacing-4x;
    background-color: $full-white;

    h2 {
      margin-top: $spacing-1x;
      margin-bottom: $spacing-1x;
    }

    p:last-child {
      margin-bottom: $spacing-3x;
    }

    @extend .shadow-z-1;

    .ngdialog-close {
      margin-top: $spacing-2x;
      margin-right: $spacing-2x;
    }
    @include phone {
      width: 95%;
      margin: 0 auto;
    }
  }

  &.ngdialog-theme-video .ngdialog-content {
    max-width: 80%;
  }
}
