.block {
  margin: $spacing-4x 0;
  &.intro {
    position: relative;

    img {
      width: 100%;
    }

    .intro-text {
      position: absolute;
      top: 0;
      left: 0;
      padding: $spacing-4x;
      color: $full-white;
    }
  }

  &.padding {
    padding: $spacing-4x;
  }

  &.block--small {
    margin: $spacing-2x 0;
  }
}

.block--large {
  margin: $spacing-8x 0;
}

.block--bottom {
  margin-bottom: $spacing-4x;
}

.inner-block,
.inner__block {
  padding-top: $spacing-4x;
  padding-bottom: $spacing-4x;

  &.block--small {
    padding-top: $spacing-2x;
    padding-bottom: $spacing-2x;
  }

  &.small,
  &.inner__block--small {
    padding-top: $spacing-1x;
    padding-bottom: $spacing-1x;
  }
}

section,
.section {
  background-color: $light-gray--primary;

  &.gray {
    background-color: $mid-gray--lighter;
  }

  &.lighter-gray {
    background-color: $light-gray--primary;
  }

  &.primary {
    background-color: $full-white;
    color: $darkest-gray;
  }

  &.success {
    background-color: $positive-feedback--green;
    color: $full-white;
  }

  &.info {
    background-color: $full-white;
    color: $dark-red-secondary;
    position: relative;
  }

  &.danger {
    background-color: $error-red;
    color: $full-white;
  }

  &.light-gray {
    background-color: $highlighted-gray;
  }

  &.black {
    background-color: $darkest-gray;
  }

  &.warning,
  &.cta {
    background-color: $warning-yellow;
    color: $full-white;
  }

  &.padded {
    padding: $spacing-1x;
  }

  &.left-right-border {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }
}
