.single-choice-filter {
  .ui-select-toggle {
    @extend .input__item;
    height: 46px;
    .btn {
      margin-top: 8px !important;
    }
  }

  .ui-select-label {
    margin-bottom: 0px;
  }
  .text-muted {
    color: #777;
    display: block;
    margin-top: 10px;
  }
  .ui-select-match-text {
    margin-top: 10px;
  }
}

.multiple-choice-filter {
  .ui-select-container {
    @extend .input__item;
    height: auto;
    &.ng-valid {
      border-color: $mid-gray;

      .ui-select-search {
        height: 40px;
        margin-top: 0;
      }
    }
  }
}

.ui-select-label,
.filter-option-label {
  margin-bottom: 0px;
  color: $darkest-gray;
  font-weight: $regular;
  font-size: 14px;
}

.ui-select-match-close {
  line-height: 1;
  color: $full-white;
  opacity: 1;
}
