// Generate margin and padding utility classes
@each $name, $size in $spacings {
  .margin--#{$name} {
    margin: $size !important;
  }
  .margin-top--#{$name} {
    margin-top: $size !important;
  }
  .margin-right--#{$name} {
    margin-right: $size !important;
  }
  .margin-bottom--#{$name} {
    margin-bottom: $size !important;
  }
  .margin-left--#{$name} {
    margin-left: $size !important;
  }
  .margin-top-bottom--#{$name} {
    margin-top: $size !important;
    margin-bottom: $size !important;
  }
  .margin-left-right--#{$name} {
    margin-left: $size !important;
    margin-right: $size !important;
  }

  .padding--#{$name} {
    padding: $size !important;
  }
  .padding-top--#{$name} {
    padding-top: $size !important;
  }
  .padding-right--#{$name} {
    padding-right: $size !important;
  }
  .padding-bottom--#{$name} {
    padding-bottom: $size !important;
  }
  .padding-left--#{$name} {
    padding-left: $size !important;
  }
  .padding-top-bottom--#{$name} {
    padding-top: $size !important;
    padding-bottom: $size !important;
  }
  .padding-left-right--#{$name} {
    padding-left: $size !important;
    padding-right: $size !important;
  }
}

@each $name, $size in $spacings {
  .position-relative-top--#{$name} {
    position: relative;
    top: $size !important;
  }
  .position-relative-right--#{$name} {
    position: relative;
    right: $size !important;
  }
  .position-relative-bottom--#{$name} {
    position: relative;
    bottom: $size !important;
  }
  .position-relative-left--#{$name} {
    position: relative;
    left: $size !important;
  }
}

.padding--none__phone {
  @include phone {
    padding: 0 !important;
  }
}

.margin--none__phone {
  @include phone {
    margin: 0 !important;
  }
}

/* Margins */
.margin--center {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Borders */

// Generate border utility classes in main colors
@each $direction in $directions {
  .border--#{$direction} {
    border-#{$direction}: $mid-gray--darker solid 1px;
  }
}

.border--all {
  @extend .border--bottom;
  @extend .border--top;
  @extend .border--left;
  @extend .border--right;
}

// Generate border utility classes in main colors
@each $name, $color in $colors {
  .border--#{$name} {
    border-color: $color;
  }
}

.border-radius {
  border-radius: $spacing-2x;
}

.border-radius--soft {
  border-radius: $border-radius-soft;
}

.border--none {
  border: none !important;
}

@each $name, $value in $border-widths {
  .border-width--#{$name} {
    border-width: $value;
  }
}

/* Visibility */

.invisible {
  visibility: hidden;
}

/* Displays */
.display--inline {
  display: inline;
}

.display--block {
  display: block;
}

.display--flex {
  @include flexbox;
}

.display--inline-block {
  display: inline-block;
}

.display--none {
  display: none !important;
}

/* Positions */
// mainly used for checkboxes to remove left margin... (remove when that bug is fixed)
.position--absolute {
  position: absolute;
}

.position--fixed {
  position: fixed;
}

.relative {
  position: relative;
}

/* Heights */
.height--full,
.full-height,
.full--height {
  height: 100%;
}

.height--full-desktop {
  min-height: 100%;
  @include phone {
    height: initial;
  }
}

.height--inherit {
  height: inherit !important;
}
.min-height--0 {
  min-height: auto !important;
}

/* Widths */
.width--inherit {
  width: inherit !important;
}

@each $name, $size in $widths {
  .width--#{$name} {
    width: #{$size};
  }
  .min-width--#{$name} {
    min-width: #{$size};
  }
}

.width--unset {
  width: unset;
}

.min-width--auto {
  min-width: auto !important;
}

/* Backgrounds */
.background-color--white {
  background-color: $full-white;
}

.background-color--info {
  background-color: $dark-gray;
}

.background-color--light-red {
  background-color: $light-red-secondary;
}

.background-color--pale-pink {
  background-color: $pink--pale;
}

.background-color--transparent {
  background-color: transparent;
}

.background-color--lightest-gray {
  background-color: $highlighted-gray;
}

// Overrides content container view

.full-width-view {
  position: absolute;
  left: 0px;
  width: 100%;
  &.fixed-top {
    top: 100px;
    @include tablet {
      top: 50px;
    }
    @include phone {
      top: 50px;
    }
  }
}

.full-width-view-mobile {
  @include phone {
    position: absolute;
    top: 100px;
    left: 0px;
    width: 100%;
    top: 50px;
    padding: $spacing-2x;
  }
}

/* Aligns */
.text-align--right {
  text-align: right !important;
}

.text-align--left {
  text-align: left !important;
}

.text-align--center {
  text-align: center !important;
}

.vertical--align,
.vertical-align {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.vertical-align--super {
  vertical-align: super;
}

.horizontal-align {
  @include transform(translateX(-50%));
  left: 50%;
}

/* Animations */
.animate {
  transition: all 500ms ease-out;
}

.animate--fast {
  transition: all 200ms ease-out;
}

.animate--bouncy {
  transition: all 200ms cubic-bezier(0.8, 0.25, 0.52, 1.22);
}

.no--animation {
  -webkit-transition: 0s none;
  transition: 0s none;
  -webkit-animation: 0s none;
  animation: 0s none;
}

.rotate {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* Shadows */
.shadow-z-1 {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
}

.shadow-z-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shadow-z-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.shadow-z-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.shadow-z-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.shadow-z-6 {
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 3px 9px rgba(0, 0, 0, 0.12);
}

.shadow--card-bottom-border {
  box-shadow: 0 4px 2px #00000014;
}

/* Outlines */
.outline {
  &:active,
  &:focus {
    outline: 1px auto $focus-blue !important;
  }
}

.no-outline {
  outline: none !important;
}

/* Scrolls */
.scrollable {
  overflow-y: scroll;
}

.scroll--x {
  overflow-x: scroll;
}

.no--scroll,
.no-scroll {
  overflow: hidden !important;
}

.no-scroll--x {
  overflow: hidden;
}

/* Floats */
.float--left {
  float: left;
}

.float--right {
  float: right;
}

.float--clear {
  clear: both;
}

.float--none {
  float: none;
}

/* Flexbox */
.flex--align {
  @include flexbox();
  @include align-items(center);
}

.flex__container {
  @include flexbox();
  @include flex-flow(row wrap);
  @include align-items(center);
}

.inline-flex__container {
  display: inline-flex;
  @include flex-flow(row wrap);
  @include align-items(center);
}

.flex__container--vertical {
  @include flexbox();
  @include flex-flow(column wrap);
}

.flex__container--seperate {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
}

.align-items--start {
  @include align-items(flex-start);
}

.align-items--center {
  @include align-items(center);
}

.align-items--baseline {
  @include align-items(baseline);
}

.align-items--stretch {
  @include align-items(stretch);
}

.align-items--end {
  @include align-items(flex-end);
}

.align-self--normal {
  @include align-self(normal);
}

.flex__item {
  @include flex-basis(50%);
}

.justify-content--space-between {
  @include justify-content(space-between);
}

.justify-content--space-around {
  justify-content: space-around;
}

.justify-content--center {
  @include justify-content(center);
}

.justify-content--end {
  @include justify-content(end);
}

/* Misc */

.visibility--hidden {
  visibility: hidden;
}

.faded {
  opacity: 0 !important;
}

.clickable-link {
  cursor: pointer;
}

.no--selection {
  @include user-select(none);
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text--wrap {
  white-space: normal;
}

/*
* This class is useful for making elements invisible but still
* able to receive tab focus
*/
.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  border: none;
  height: 1px;
  width: 1px;
  margin: -1px !important;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.word-break--word {
  word-break: break-word;
}
