@mixin flexbox() {
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin flex-flow($values) {
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -webkit-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

@mixin flex-direction($values) {
  -webkit-flex-direction: $values;
  -moz-flex-direction: $values;
  -webkit-flex-direction: $values;
  -ms-flex-direction: $values;
  flex-direction: $values;
}

@mixin flex-wrap($values) {
  -webkit-flex-wrap: $values;
  -moz-flex-wrap: $values;
  -webkit-flex-wrap: $values;
  -ms-flex-wrap: $values;
  flex-wrap: $values;
}

@mixin justify-content($values) {
  -webkit-justify-content: $values;
  -moz-justify-content: $values;
  -webkit-justify-content: $values;
  -ms-justify-content: $values;
  justify-content: $values;
}

@mixin flex-basis($values) {
  -webkit-flex-basis: $values;
  -moz-flex-basis: $values;
  -webkit-flex-basis: $values;
  -ms-flex-basis: $values;
  flex-basis: $values;
}

@mixin align-items($values) {
  -webkit-align-items: $values;
  -moz-align-items: $values;
  -webkit-align-items: $values;
  -ms-align-items: $values;
  align-items: $values;
}

@mixin align-self($values) {
  -webkit-align-self: $values;
  -moz-align-self: $values;
  -webkit-align-self: $values;
  -ms-align-self: $values;
  align-self: $values;
}

@mixin transform($values) {
  -webkit-transform: $values;
  -moz-transform: $values;
  -ms-transform: $values;
  transform: $values;
}

@mixin animation($values) {
  -webkit-animation: #{$values};
  -moz-animation: #{$values};
  -ms-animation: #{$values};
  animation: #{$values};
}

@mixin transition($values) {
  -webkit-transition: #{$values};
  -moz-transition: #{$values};
  -ms-transition: #{$values};
  transition: #{$values};
}

@mixin appearance($values) {
  -webkit-appearance: $values;
  -moz-appearance: $values;
  appearance: $values;
}

@mixin user-select($values) {
  -webkit-user-select: $values;
  -moz-user-select: $values;
  -khtml-user-select: $values;
  -ms-user-select: $values;
}

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}
