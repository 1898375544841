// Spacing
$spacing-half-1x: 4px;
$spacing-1x: 8px;
$spacing-2x: 16px;
$spacing-3x: 24px;
$spacing-4x: 32px;
$spacing-5x: 40px;
$spacing-6x: 48px;
$spacing-7x: 56px;
$spacing-8x: 64px;
$spacing-10x: 80px;

$spacings: (
  none: 0,
  half-1x: $spacing-half-1x,
  1x: $spacing-1x,
  2x: $spacing-2x,
  3x: $spacing-3x,
  //default
    4x: $spacing-4x,
  5x: $spacing-5x,
  6x: $spacing-6x,
  7x: $spacing-7x,
  8x: $spacing-8x,
  10x: $spacing-10x,
);

// Grid sizes and padding
$content-width--default: 1280px;
$content-width--desktop: 1024px;
$content-width--tablet: 100%;
$content-width--phone: 100%;

$content-padding--default: 50px;
$content-padding--desktop: 15px;
$content-padding--tablet: 15px;
$content-padding--phone: 15px;

// Nav bar heigth
$nav-bar-height--default: 100px;
$nav-bar-height--desktop: 100px;
$nav-bar-height--tablet: 50px;
$nav-bar-height--phone: 50px;

// Font styles
$line: 100;
$light: 300; //Frångick styleguiden här, ska egentligen vara 400 men för att kunna ha quattro news som EN family och särskilja på weight och style
$regular: 400;
$medium: 500;
$bold: 600;
$ultra-bold: 700;

//Colors---------

//Primary
$tv4-red: #ff3334;
$light-gray--primary: #f4f2f1;
$dark-gray--primary: #191817;

//Secondary
$dark-red-secondary: #710923;
$mid-red-secondary: #fb666a;
$light-red-secondary: #fdafaf;

//Gray
$full-white: #ffffff;
$highlighted-gray: #f5f5f5;
$light-gray: #edebea;

$mid-gray--lightest: #e5e5e5;
$mid-gray--lighter: #e6e6e6;
$mid-gray: #e1e1e1;
$mid-gray--darker: #cccccc;
$mid-gray--darkest: #a3a3a3;

$dark-gray: #808080;
$darker-gray: #616161;
$darkest-gray: #4c4644;

$grey-disabled: #dedede;

//Variants
$hover--red: #da2c2d;
$disable-red: #f99191;
$pink: #fdafaf;
$hover-pink: #ffeaea;
$pink--pale: #fff0f0;
$dark-green: #2ca085;
$green: #52d3ae;
$light-mint-green: #e1faf2;

//Semantic
$positive-feedback--green: #1ea988;
$warning-yellow: #f0aa21;
$error-red: #fb666a;
$focus-blue: #007aff;

//Misc
$brown: #8f8682;
$legacy-blue: #1fbde7;

$colors: (
  info: $dark-gray,
  danger: $error-red,
  cta: $warning-yellow,
  success: $positive-feedback--green,
);

$border-widths: (
  thin: 1px,
  medium: 2px,
  strong: 3px,
);

$directions: (top, right, bottom, left);

$widths: (
  0: 0%,
  20: 20%,
  25: 25%,
  30: 30%,
  33: 33%,
  50: 50%,
  66: 66%,
  70: 70%,
  75: 75%,
  100: 100%,
);

$border-radius-soft: 4px;

/* When updating, remember to update variables in ViewportService */
$desktop-upper: 1199px;
$tablet-upper: 1024px;
$phone-upper: 768px;
$phone-lower: 767px;

@mixin desktop {
  @media (max-width: #{$desktop-upper}) and (min-width: #{$tablet-upper + 1}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$phone-upper + 1}) and (max-width: #{$tablet-upper}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$phone-upper}) {
    @content;
  }
}

@mixin tablet-or-phone {
  @media (max-width: #{$tablet-upper}) {
    @content;
  }
}
