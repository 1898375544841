button {
  border: 0;
  background: transparent;
  background-color: transparent;
}

$button-margin: 4px;
$button-min-height: $spacing-5x;
$button-font-weight: $regular;
.button {
  border: 0;
  white-space: nowrap;
  font-size: 16px;
  font-weight: $button-font-weight;
  border-radius: 40px;
  padding: $spacing-1x $spacing-4x;
  outline: none;
  margin: $button-margin;
  min-width: 120px;
  min-height: $button-min-height;
  background-color: $tv4-red;
  color: $full-white;

  &:hover,
  &:focus {
    outline: none;
    background-color: $hover--red;
    color: $full-white;
  }

  &:active {
    color: $full-white;
    background-color: $tv4-red;
  }

  &.loading {
    &::before,
    &::after {
      background-color: $full-white;
    }
  }

  &.feather {
    padding-left: $spacing-6x;
    width: initial;
    .feather {
      position: absolute;
      top: 5px;
      left: 6px;
      .feather--base,
      .feather--layer-1,
      .feather--layer-2,
      .feather--layer-3,
      .feather--layer-4,
      .feather--layer-5 {
        &:before,
        &:after {
          height: 15px;
          width: 11px;
          background-color: $full-white;
        }
      }
    }
  }

  &.primary-1 {
    background-color: $tv4-red;
    color: $full-white;

    &:hover,
    &:focus,
    &:active {
      background-color: $hover--red;
    }
  }

  &.primary-2 {
    background-color: $full-white;
    color: $tv4-red;

    &:hover,
    &:focus {
      background-color: $pink;
    }

    &:active {
      background-color: $pink--pale;
    }
  }

  &.secondary {
    background-color: $full-white;
    color: $tv4-red;
    border: $tv4-red 1px solid;

    &:hover,
    &:active,
    &:focus {
      background-color: $hover-pink;
      color: $tv4-red;
    }

    &.success {
      color: $positive-feedback--green;
      background-color: $full-white;
      border: $positive-feedback--green 1px solid;
      &:hover,
      &:focus {
        background-color: $green;
      }
      &:active {
        background-color: $green;
        color: $full-white;
      }
    }
  }

  &.success {
    color: $full-white;
    background-color: $positive-feedback--green;
    &:hover,
    &:focus {
      background-color: $green;
    }
    &:active {
      background-color: $green;
    }
  }

  &.danger {
    background-color: $error-red;
    color: $full-white;
    &:hover,
    &:focus {
      background-color: $hover--red;
    }
    &:active {
      background-color: $hover--red;
    }
  }

  &.disabled,
  &:disabled {
    background-color: $light-red-secondary;
    color: $full-white;
    border: none;
    cursor: not-allowed;
    &:hover,
    &:focus {
      background-color: $light-red-secondary;
    }
  }
  &.secondary.disabled,
  &.secondary:disabled {
    background-color: $full-white;
    color: $light-red-secondary;
    border: 1px solid $light-red-secondary;
    cursor: not-allowed;
    &:hover,
    &:focus {
      background-color: $full-white;
    }
  }

  &.block {
    width: 100%;
    margin: $button-margin 0;
  }

  &.center {
    margin: $button-margin auto;
  }
}

a {
  font-size: 16px;
  font-weight: $button-font-weight;

  color: $tv4-red;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $hover--red;
  }

  &.primary-1 {
    color: $tv4-red;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &.primary-2 {
    color: $full-white;
    &:hover,
    &:focus {
      text-decoration: underline;
      color: $full-white;
    }
  }
}

.link {
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: $button-font-weight;
  min-height: $button-min-height;
  margin: $button-margin;

  color: $tv4-red;
  &:hover,
  &:focus {
    outline: none;
    text-decoration: underline;
    color: $tv4-red;
  }

  &.primary-1 {
    color: $tv4-red;
    &:hover,
    &:focus {
      text-decoration: underline;
      color: $tv4-red;
    }
  }

  &.primary-2 {
    color: $dark-gray--primary;
    &:hover,
    &:focus {
      text-decoration: underline;
      color: $dark-gray--primary;
    }
  }

  &.disabled,
  &:disabled {
    color: $grey-disabled;
    cursor: not-allowed;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
