campaign-progress {
  circle-progress {
    svg {
      display: block;
      margin: 0 auto;
      text {
        transform: translateY(8px);
      }
    }
  }
}
