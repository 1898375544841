.status-icon {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: all 250ms ease-out;
  padding: 0;
  margin: 8px auto;
  font-family: 'tv4-icon-font';
  color: $full-white;
  font-size: 25px;
  line-height: 50px;

  &.booked {
    background-color: $positive-feedback--green;
  }
  &.saved {
    background-color: $warning-yellow;
  }
  &.stopped {
    background-color: $error-red;
  }
  &.money {
    background-color: $tv4-red;
  }
}
