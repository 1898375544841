@font-face {
  font-family: 'tv4-icon-font';
  src: url('../fonts/tv4-icon-font.ttf?go91kf') format('truetype'),
    url('../fonts/tv4-icon-font.woff?go91kf') format('woff'),
    url('../fonts/tv4-icon-font.svg?go91kf#tv4-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tv4-icon-font' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-open-new-window:before {
  content: '\e963';
}
.icon-waiting-filled:before {
  content: '\e96e';
}
.icon-waiting-outline:before {
  content: '\e983';
}
.icon-not-happy:before {
  content: '\e962';
}
.icon-pause-outlined:before {
  content: '\e95d';
}
.icon-play-outlined:before {
  content: '\e95e';
}
.icon-close-x:before {
  content: '\e98f';
}
.icon-shopping-cart:before {
  content: '\e990';
}
.icon-thumbnails-grid:before {
  content: '\e99c';
}
.icon-thumbs-large:before {
  content: '\e9a3';
}
.icon-thumbs-single-row:before {
  content: '\e9a4';
}
.icon-heads-up-outline:before {
  content: '\e9a2';
}
.icon-question-mark-outline:before {
  content: '\e9a0';
}
.icon-change-user:before {
  content: '\e96a';
}
.icon-android:before {
  content: '\e95f';
}
.icon-ios:before {
  content: '\e960';
}
.icon-pause-filled:before {
  content: '\e961';
}
.icon-locked:before {
  content: '\e964';
}
.icon-unlocked:before {
  content: '\e965';
}
.icon-off:before {
  content: '\e966';
}
.icon-on:before {
  content: '\e967';
}
.icon-calendar:before {
  content: '\e968';
}
.icon-Category:before {
  content: '\e969';
}
.icon-check-filled:before {
  content: '\e96b';
}
.icon-check:before {
  content: '\e96c';
}
.icon-close-x-filled:before {
  content: '\e96d';
}
.icon-continue-watching:before {
  content: '\e96f';
}
.icon-download:before {
  content: '\e970';
}
.icon-edit:before {
  content: '\e971';
}
.icon-eye-hide:before {
  content: '\e972';
}
.icon-family-kids:before {
  content: '\e973';
}
.icon-happy:before {
  content: '\e974';
}
.icon-home:before {
  content: '\e975';
}
.icon-jump-back:before {
  content: '\e976';
}
.icon-jump-forward:before {
  content: '\e977';
}
.icon-link:before {
  content: '\e978';
}
.icon-live-dot:before {
  content: '\e979';
}
.icon-log-out:before {
  content: '\e97a';
}
.icon-mail:before {
  content: '\e97b';
}
.icon-maximize:before {
  content: '\e97c';
}
.icon-menu:before {
  content: '\e97d';
}
.icon-minimize:before {
  content: '\e97e';
}
.icon-miniplayer:before {
  content: '\e97f';
}
.icon-more:before {
  content: '\e980';
}
.icon-movies:before {
  content: '\e981';
}
.icon-News:before {
  content: '\e982';
}
.icon-notification:before {
  content: '\e984';
}
.icon-play-filled:before {
  content: '\e985';
}
.icon-plus:before {
  content: '\e986';
}
.icon-program-guide:before {
  content: '\e987';
}
.icon-refresh:before {
  content: '\e988';
}
.icon-replay:before {
  content: '\e989';
}
.icon-search:before {
  content: '\e98a';
}
.icon-series:before {
  content: '\e98b';
}
.icon-shield:before {
  content: '\e98c';
}
.icon-sport:before {
  content: '\e98e';
}
.icon-trash:before {
  content: '\e992';
}
.icon-tv-channels:before {
  content: '\e993';
}
.icon-User:before {
  content: '\e994';
}
.icon-volume-1:before {
  content: '\e995';
}
.icon-volume-2:before {
  content: '\e996';
}
.icon-volume-mute:before {
  content: '\e997';
}
.icon-volume:before {
  content: '\e998';
}
.icon-bookmark:before {
  content: '\e999';
}
.icon-bookmarked:before {
  content: '\e99a';
}
.icon-heads-up-filled:before {
  content: '\e99b';
}
.icon-info-filled:before {
  content: '\e99d';
}
.icon-info-outline:before {
  content: '\e99e';
}
.icon-question-mark-filled:before {
  content: '\e99f';
}
.icon-warning-outline:before {
  content: '\e9a1';
}
.icon-TV4_001_kategorier:before {
  content: '\e900';
}
.icon-TV4_002_sok:before {
  content: '\e901';
}
.icon-TV4_003_anvandare:before {
  content: '\e902';
}
.icon-TV4_004_dela:before {
  content: '\e903';
}
.icon-TV4_005_spara:before {
  content: '\e904';
}
.icon-TV4_006_support:before {
  content: '\e905';
}
.icon-TV4_007_stang:before {
  content: '\e906';
}
.icon-TV4_008_fram:before {
  content: '\e907';
}
.icon-TV4_009_bak:before {
  content: '\e908';
}
.icon-TV4_010_upp:before {
  content: '\e909';
}
.icon-TV4_011_ner:before {
  content: '\e90a';
}
.icon-TV4_012_check:before {
  content: '\e90b';
}
.icon-TV4_013_filter:before {
  content: '\e90c';
}
.icon-TV4_014_placeholder:before {
  content: '\e90d';
}
.icon-TV4_015_meny:before {
  content: '\e90e';
}
.icon-TV4_016_kommentar:before {
  content: '\e90f';
}
.icon-TV4_017_tidsbegransad:before {
  content: '\e910';
}
.icon-TV4_024_chromecast:before {
  content: '\e911';
}
.icon-TV4_025_airplay:before {
  content: '\e912';
}
.icon-TV4_026_kontakt:before {
  content: '\e913';
}
.icon-TV4_028_desktop:before {
  content: '\e914';
}
.icon-TV4_029_tablet:before {
  content: '\e915';
}
.icon-TV4_030_smartphone:before {
  content: '\e916';
}
.icon-TV4_031_nyheter:before {
  content: '\e917';
}
.icon-TV4_032_tillatet-for-alla:before {
  content: '\e918';
}
.icon-TV4_033_tillatet-fran-7-ar:before {
  content: '\e919';
}
.icon-TV4_034_tillatet-fran-11-ar:before {
  content: '\e91a';
}
.icon-TV4_035_tillatet-fran-15-ar:before {
  content: '\e91b';
}
.icon-TV4_036_tillatet-fran-18-ar:before {
  content: '\e91c';
}
.icon-TV4_037_fotboll:before {
  content: '\e91d';
}
.icon-TV4_038_ishockey:before {
  content: '\e91e';
}
.icon-TV4_039_ovrig_sport:before {
  content: '\e91f';
}
.icon-TV4_040_live_sport:before {
  content: '\e920';
}
.icon-TV4_041_utforska:before {
  content: '\e921';
}
.icon-TV4_043_instagram:before {
  content: '\e922';
}
.icon-TV4_044_pinterest:before {
  content: '\e923';
}
.icon-TV4_045_facebook:before {
  content: '\e924';
}
.icon-TV4_046_twitter:before {
  content: '\e925';
}
.icon-TV4_047_kortbetalning:before {
  content: '\e926';
}
.icon-TV4_048_fakturabetalning:before {
  content: '\e927';
}
.icon-TV4_049_direktbetalning_internetbank:before {
  content: '\e928';
}
.icon-TV4_050_saker_betalning:before {
  content: '\e929';
}
.icon-TV4_051_valdsinslag:before {
  content: '\e92a';
}
.icon-TV4_052_sexuellt-innehall:before {
  content: '\e92b';
}
.icon-TV4_053_kan_skapa_angest:before {
  content: '\e92c';
}
.icon-TV4_054_innehaller_rusningsmedel:before {
  content: '\e92d';
}
.icon-TV4_055_premiar:before {
  content: '\e92e';
}
.icon-TV4_056_direktsandning:before {
  content: '\e92f';
}
.icon-TV4_057_sista_chansen:before {
  content: '\e930';
}
.icon-TV4_058_skrattar:before {
  content: '\e931';
}
.icon-TV4_059_glad:before {
  content: '\e932';
}
.icon-TV4_060_flirt:before {
  content: '\e933';
}
.icon-TV4_061_nojd:before {
  content: '\e934';
}
.icon-TV4_062_ledsen:before {
  content: '\e935';
}
.icon-TV4_063_arg:before {
  content: '\e936';
}
.icon-TV4_064_trott:before {
  content: '\e937';
}
.icon-TV4_065_visa:before {
  content: '\e938';
}
.icon-TV4_066_dolj_1:before {
  content: '\e939';
}
.icon-TV4_066_dolj_2:before {
  content: '\e93a';
}
.icon-TV4_067_golf:before {
  content: '\e93b';
}
.icon-TV4_068_film:before {
  content: '\e93c';
}
.icon-TV4_069_barn:before {
  content: '\e93d';
}
.icon-TV4_070_serier:before {
  content: '\e93e';
}
.icon-TV4_071_animerat:before {
  content: '\e93f';
}
.icon-TV4_072_action:before {
  content: '\e940';
}
.icon-TV4_073_drama:before {
  content: '\e941';
}
.icon-TV4_074_deckare:before {
  content: '\e942';
}
.icon-TV4_075_dokumentar:before {
  content: '\e943';
}
.icon-TV4_076_familj:before {
  content: '\e944';
}
.icon-TV4_077_fantasy:before {
  content: '\e945';
}
.icon-TV4_078_komedi:before {
  content: '\e946';
}
.icon-TV4_079_thriller:before {
  content: '\e947';
}
.icon-TV4_080_skrack:before {
  content: '\e948';
}
.icon-TV4_081_science-fiction:before {
  content: '\e949';
}
.icon-TV4_082_svenskt:before {
  content: '\e94a';
}
.icon-TV4_083_ovriga-genres:before {
  content: '\e94b';
}
.icon-TV4_084_TV:before {
  content: '\e94c';
}
.icon-TV4_085_undo:before {
  content: '\e94d';
}
.icon-TV4_086_favoritborttagen:before {
  content: '\e94e';
}
.icon-TV4_087_film-serier:before {
  content: '\e94f';
}
.icon-TV4_089_video:before {
  content: '\e950';
}
.icon-TV4_092_rating:before {
  content: '\e951';
}
.icon-TV4_094_skriv-ut:before {
  content: '\e952';
}
.icon-TV4_096_e-handel:before {
  content: '\e953';
}
.icon-TV4_102_smartTV:before {
  content: '\e954';
}
.icon-TV4_103_x-box:before {
  content: '\e955';
}
.icon-TV4_106_multiple-devices:before {
  content: '\e956';
}
.icon-TV4_113_reklamfritt:before {
  content: '\e957';
}
.icon-TV4_114_klipp:before {
  content: '\e958';
}
.icon-TV4_115_instream:before {
  content: '\e959';
}
.icon-TV4_116_outstream:before {
  content: '\e95a';
}
.icon-TV4_117_styrning:before {
  content: '\e95b';
}
.icon-TV4_118_lokal_styrning:before {
  content: '\e95c';
}

.icon__large {
  font-size: 32px;
}

.icon__semi-large {
  font-size: 28px;
}

.icon__medium {
  font-size: 24px;
}

.icon__semi-medium {
  font-size: 20px;
}

.icon__small {
  font-size: 16px;
}

.icon__tiny {
  font-size: 12px;
}

.icon__1-5em {
  font-size: 1.5em;
}

.icon__1-2em {
  font-size: 1.2em;
}
