.dashboard-area {
  transition: all 600ms ease-in-out;
  padding-right: 20px;
  margin-top: 100px;

  &.extra__row {
    margin-top: 155px;
  }
}

.dashboard {
  .campaign__button {
    display: block;
    .icon {
      font-size: 16px;
      &:hover {
        transition: all 400ms ease-in-out;
      }
    }
  }

  &.dashboard--block {
    @extend .no--selection;
    margin: 50px 0;
  }

  &.list {
    .campaign-card {
      height: 75px;
      width: 100%;
      display: block;
      margin-bottom: 2px;
      position: relative;
      .graph {
        display: none;
      }
      .progress-area {
        display: none;
      }

      .campaign__content {
        padding: 0;
        height: 75px;
        font-size: 14px;
        .top,
        .mid,
        .bot {
          height: 75px;
          margin: 0;
          float: left;
        }

        .top {
          width: 33%;
          padding: 8px;
        }
        .mid {
          width: 26%;
          overflow: hidden;
          .table {
            width: 50%;
            display: inline;
            margin-right: $spacing-2x;
          }
        }
        .bot {
          width: 38%;
          float: right;
          display: block;
          margin: $spacing-1x;
          .status {
            padding: 0;
            .item {
              width: 30%;
              text-align: center;
              font-size: 14px;
            }

            .edit__button {
              display: inline;
              float: right;
              margin-top: 24px;
            }

            .status-badge {
              margin: 0 auto;
            }

            .item + .item {
              border: 0;
            }
          }

          &.planner {
            .status {
              .item {
                width: 20%;
                float: none;
                display: inline-block;
              }
            }
          }
        }
      }

      .footer {
        display: none;
      }
    }
  }
}

.absolute-top-right-corner {
  position: absolute;
  right: 0;
  top: 0;
}

.campaign-card {
  @extend .no--selection;
  width: 250px;
  height: 400px;
  background-color: $full-white;
  box-shadow: -2px 0 0 0 $mid-red-secondary;
  color: $dark-gray--primary;
  float: left;
  margin: 8px 16px 8px 0px;
  position: relative;
  cursor: pointer;

  &.unbooked {
    height: 330px;
  }

  &.with-top-diagram {
    height: 420px;
  }

  .special-campaign-label--inline-with-customer-name {
    margin-left: 4px;
  }

  .graph {
    height: 65px;
    background-color: $hover-pink;
    .chart-container {
      height: inherit;
      width: inherit;
      canvas {
        width: 100% !important;
      }
    }
  }

  .card-top {
    height: 27px;
  }

  .progress-area {
    height: 20px;
    background-color: $mid-gray--darkest;
    padding: 7px 8px 6px 8px;
    color: $full-white;
    .progress {
      transition: none;
      border-radius: 3px;
      background-color: darken($mid-gray--darkest, 4%);
      width: 75%;
      margin: 0;
      height: 6px;
      .bar {
        background-color: $full-white;
        height: 6px;
        border-radius: 3px;
      }
    }
    .percentage {
      font-size: 0.8em;
      font-weight: $light;
      line-height: 0.6em;
    }
  }
  .campaign__content {
    padding: 4px 16px;
    width: inherit;
    .client {
      font-weight: $light;
      font-size: 1em;
    }
    .name {
      font-weight: 500;
      font-size: 18px;
      color: $tv4-red;
    }

    .mid {
      .table {
        margin: 0;
        font-size: 0.8em;
        tr > td {
          border: 0;
          padding: 4px 0;
          line-height: 0.8em;
        }
      }

      .table + .table {
        margin: 0;
      }
    }
    .bot {
      .status {
        padding: 16px 0;
        .item {
          width: 50%;
          float: left;
          text-align: center;
        }

        .edit__button {
          text-align: center;
        }

        .item + .item {
          border-left: 1px solid $mid-gray--darker;
        }
      }

      &.planner {
        .status {
          padding: 16px 0;
          .item {
            width: 31.8%;
            float: none;
            display: inline-block;
          }
          .edit__button {
            margin-top: 0;
          }
        }
      }
    }
  }
  .footer {
    padding: 0px 16px 3px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 40px;
    color: $dark-gray;
    line-height: 35px;
    font-size: 14px;
  }

  &.active {
    box-shadow: 0 0 0 2px $positive-feedback--green;
  }

  &.success {
    .progress-area {
      background-color: $positive-feedback--green;
      .progress {
        background-color: darken($positive-feedback--green, 5%);
      }
    }
  }

  &.error {
    .progress-area {
      background-color: $error-red;
      .progress {
        background-color: darken($error-red, 15%);
      }
    }
  }

  &.warning {
    .progress-area {
      background-color: $warning-yellow;
      .progress {
        background-color: darken($warning-yellow, 15%);
      }
    }
  }

  &:hover {
    box-shadow: 0 0 0 2px $green;
  }

  &.list {
    height: 75px;
    width: 100%;
    display: block;
    margin: 0;
    position: relative;
    margin-bottom: 2px;
    .graph {
      display: none;
    }
    .progress-area {
      display: none;
    }

    .campaign__content {
      padding: 0;
      height: 75px;
      font-size: 14px;
      .top,
      .mid,
      .bot {
        height: 75px;
        margin: 0;
        float: left;
      }

      .top {
        width: 33%;
        padding: 8px;
      }
      .mid {
        width: 33%;
        .table {
          width: 50%;
          display: inline-block;
          float: left;
          margin: $spacing-1x 0 !important;
        }
      }
      .bot {
        width: 30%;
        float: right;
        display: block;
        margin: $spacing-1x;
        .status {
          padding: 16px 0;
          .item {
            text-align: center;
            font-size: 14px;
          }

          .status-badge {
            margin: 0 auto;
          }

          .item + .item {
            border: 0;
          }
        }
      }
    }

    .footer {
      display: none;
    }
  }
}

.filters {
  position: fixed;
  background-color: $light-gray;
  width: 100%;
  top: 100px;
  left: 0;
  transition: all 600ms ease-in-out;
  display: block;
  z-index: 10;
  min-height: 86px;

  &.extra__row {
    min-height: 145px;
  }

  .button__group {
    margin-right: 40px;
  }

  .status-badge {
    width: 40px;
    height: 40px;
    margin: 22px 0px;
    display: inline-flex;
  }
  .search_button {
    position: relative;
    top: -5px;
  }
}

[data-placement='top'] ~ .tooltip .tooltip-arrow {
  border-top-color: $full-white;
}

.video__holder {
  &:nth-child(even) {
    background-color: darken($full-white, 2%);
  }
}

.tabbable__panel {
  padding: $spacing-1x 4px;
  border-radius: $border-radius-soft;
  border-top-left-radius: 0;
  box-shadow: none;
  background-color: $full-white;

  &.right {
    border-top-right-radius: 0;
  }

  &.rounded-top-right {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.panel-margin {
    margin-bottom: 24px;
  }

  &.boxed {
    border-radius: 0;
  }
}

.tabbable__body {
  @extend .shadow--card-bottom-border;
  background-color: $full-white;
  padding: $spacing-2x;
  border-top-right-radius: $border-radius-soft;
  border-top-left-radius: $border-radius-soft;
  margin-top: 24px;
}

.command-button {
  &.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $pink;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 1000ms, box-shadow 300ms ease-in-out;
    margin-left: 1px;
  }

  .icon {
    font-size: 32px;
    font-weight: $ultra-bold;
    color: $dark-red-secondary;

    &.darker {
      color: $dark-gray;
    }
  }
}

/* 1199 - 1024 */
@include desktop {
}

/* 1024-768 */
@include tablet {
  .filter-display {
    top: 50px;
  }

  .filters {
    top: 50px;
  }

  .dashboard-area {
    padding-right: 0;
    padding-top: 50px;
  }

  .dashboard {
    &.dashboard--block {
      margin-top: 50px;
    }
  }

  .campaign-card {
    height: 150px;
    width: 100%;
    display: block;
    margin: 0;
    border-bottom: 1px solid $highlighted-gray;

    .graph {
      display: none;
    }
    .progress-area {
      display: none;
    }
    .campaign__content {
      .top {
        width: 100%;
        text-align: center;
      }
      .mid {
        width: 50%;
        float: left;
      }
      .bot {
        width: 50%;
        float: left;
      }
    }
    .footer {
      display: none;
    }
  }
}

/* 758 - 1 */
@include phone {
  .filter-display {
    top: 50px;
    width: 100%;
    padding: 12px 16px;
  }

  .filters {
    top: 50px;
  }

  .filter-row {
    padding: 0 $spacing-1x;
    label {
      display: block;
    }

    .filter {
      margin: 4px;
    }
  }

  .dashboard-area {
    padding-right: 0;
    padding-top: 50px;
  }

  .dashboard {
    &.dashboard--block {
      margin-top: 50px;
    }
  }

  .campaign-card {
    height: 150px;
    width: 100%;
    display: block;
    margin: 0;
    border-bottom: 1px solid $highlighted-gray;
    transition: all 300ms linear;

    .graph {
      display: none;
    }
    .progress-area {
      display: none;
    }
    .campaign__content {
      .top {
        width: 100%;
        text-align: center;
      }
      .mid {
        width: 100%;
        float: left;
      }
      .bot {
        display: none;
      }
    }
    .footer {
      max-height: 0px;
      padding: 0;
      margin: 0;
      border: 0;
    }

    &.active {
      height: 190px;
      .footer {
        text-align: center;
        max-height: 40px;
      }
    }
  }
}

.cancelled__cover {
  display: block;
  position: absolute;
  z-index: 1000;
  height: 100%;
  overflow: hidden;
  margin-top: -20px;
  background: repeating-linear-gradient(
    -45deg,
    rgba($highlighted-gray, 0.5),
    rgba($highlighted-gray, 0.5) 10px,
    rgba($mid-gray--darker, 0.5) 10px,
    rgba($mid-gray--darker, 0.5) 20px
  );
}

.search--box {
  span {
    position: absolute;
    right: 10px;
    bottom: -11px;
    font-size: 20px;
    color: $tv4-red;
    line-height: 60px;
    cursor: pointer;
    z-index: 10;
  }
}

.loader--height {
  height: 55px;
}

.chart--size {
  width: 200px;
  margin: 0 auto;
  margin-top: -23px;
}

.thumbnail__image {
  height: 67px;
}
